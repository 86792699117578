"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var ImageSlider = /** @class */ (function (_super) {
    __extends(ImageSlider, _super);
    function ImageSlider(el) {
        var _this = _super.call(this, el) || this;
        _this.onMouseoverControlNext = function () {
            var _a;
            if ((_a = _this.controlNext) === null || _a === void 0 ? void 0 : _a.classList.contains('swiper-button-disabled')) {
                return;
            }
            _this.onMouseoverControl();
        };
        _this.onMouseoverControlPrev = function () {
            var _a;
            if ((_a = _this.controlPrev) === null || _a === void 0 ? void 0 : _a.classList.contains('swiper-button-disabled')) {
                return;
            }
            _this.onMouseoverControl();
        };
        _this.onMouseoverControl = function () {
            _this.el.classList.add('image-slider--mouseover');
        };
        _this.onMouseoutControl = function () {
            _this.el.classList.remove('image-slider--mouseover');
        };
        _this.controlNext = _this.el.querySelector('[data-slider-control-next]');
        _this.controlPrev = _this.el.querySelector('[data-slider-control-prev]');
        _this.swiper = _this.el.querySelector('.swiper');
        return _this;
    }
    ImageSlider.prototype.init = function () {
        var _this = this;
        var _a, _b, _c, _d;
        (_a = this.controlNext) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseover', this.onMouseoverControlNext);
        (_b = this.controlPrev) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseover', this.onMouseoverControlPrev);
        (_c = this.controlNext) === null || _c === void 0 ? void 0 : _c.addEventListener('mouseout', this.onMouseoutControl);
        (_d = this.controlPrev) === null || _d === void 0 ? void 0 : _d.addEventListener('mouseout', this.onMouseoutControl);
        if (!this.swiper) {
            return;
        }
        var component = (0, component_1.getInstance)(this.swiper, 'GenericSwiper');
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        var swiperInstance = component === null || component === void 0 ? void 0 : component.swiperInstance;
        if (!swiperInstance) {
            window.setTimeout(function () {
                _this.init();
            }, 10);
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        swiperInstance.on('touchStart', this.onMouseoverControl);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        swiperInstance.on('touchEnd', this.onMouseoutControl);
    };
    return ImageSlider;
}(component_1.Component));
exports.default = ImageSlider;
