"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    s: {
        min: 0,
        max: 699,
    },
    m: {
        min: 700,
        max: 1279,
    },
    l: {
        min: 1280,
        max: 1599,
    },
    xl: {
        min: 1600,
        max: null,
    }
};
