"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var className = 'kb-focus';
var TaikoLibAccessibility = /** @class */ (function () {
    function TaikoLibAccessibility() {
        var _this = this;
        /**
         * true if the current input is a mouse click
         *
         * @memberof Accessibility
         */
        this.isClick = false;
        /**
         * the element that has focus
         *
         * @memberof Accessibility
         */
        this.elementWithFocus = null;
        /**
         * when true we will ignore the next mouse click event
         *
         * @memberof Accessibility
         */
        this.ignoreClickEvent = false;
        /**
         * if true the window has lost focus
         *
         * @memberof Accessibility
         */
        this.windowLostFocus = false;
        /**
         * true if user is currently navigating with keyboard
         *
         * @memberof Accessibility
         */
        this.keyboardNavigationActive = false;
        this.onWindowBlur = function (e) {
            _this.ignoreClickEvent = false;
            // Save focus loss state if no keyboard is used to navigate
            if (!_this.keyboardNavigationActive) {
                _this.windowLostFocus = true;
            }
            _this.remove(e.target);
        };
        this.remove = function (eventTarget) {
            if (!eventTarget || !_this.elementWithFocus) {
                return;
            }
            _this.elementWithFocus.classList.remove(className);
            _this.elementWithFocus = null;
        };
        this.onMouseListener = function (e) {
            if (_this.ignoreClickEvent) {
                _this.ignoreClickEvent = false;
                return;
            }
            _this.keyboardNavigationActive = false;
            _this.isClick = true;
            _this.remove(e.target);
        };
        this.onKeyDownListener = function (e) {
            _this.isClick = false;
            switch (e.key) {
                case 'Enter':
                case 'Space':
                case 'Left': // IE/Edge specific value
                case 'ArrowLeft':
                case 'Right': // IE/Edge specific value
                case 'ArrowRight':
                    _this.ignoreClickEvent = true;
                    break;
                default:
                    return; // Quit when this doesn't handle the key event.
            }
        };
        this.onKeyupListener = function (e) {
            _this.isClick = false;
        };
        this.onFocusInListener = function (e) {
            var eventTarget = e.target;
            _this.remove(eventTarget);
            if (!eventTarget ||
                !e.target.closest ||
                _this.windowLostFocus) {
                _this.windowLostFocus = false;
                return;
            }
            _this.add(eventTarget);
        };
        this.init();
    }
    TaikoLibAccessibility.prototype.init = function () {
        document.addEventListener('keyup', this.onKeyupListener, { capture: false, passive: true });
        document.addEventListener('click', this.onMouseListener, { capture: false, passive: true });
        document.addEventListener('mousedown', this.onMouseListener, { capture: false, passive: true });
        document.addEventListener('mouseup', this.onMouseListener, { capture: false, passive: true });
        document.addEventListener('keydown', this.onKeyDownListener, { capture: false, passive: true });
        document.addEventListener('focusin', this.onFocusInListener, { capture: true, passive: true });
        window.addEventListener('blur', this.onWindowBlur, { capture: false, passive: true });
    };
    TaikoLibAccessibility.prototype.destroy = function () {
        document.removeEventListener('keyup', this.onKeyupListener);
        document.removeEventListener('click', this.onMouseListener);
        document.removeEventListener('mousedown', this.onMouseListener);
        document.removeEventListener('mouseup', this.onMouseListener);
        document.removeEventListener('keydown', this.onKeyDownListener);
        document.removeEventListener('focusin', this.onFocusInListener);
        window.removeEventListener('blur', this.onWindowBlur);
    };
    TaikoLibAccessibility.prototype.add = function (eventTarget) {
        if (this.isClick) {
            return;
        }
        if (eventTarget === null) {
            return;
        }
        this.elementWithFocus = eventTarget;
        eventTarget.classList.add(className);
        this.keyboardNavigationActive = true;
    };
    return TaikoLibAccessibility;
}());
exports.default = new TaikoLibAccessibility();
