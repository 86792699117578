"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var TextMedia = /** @class */ (function (_super) {
    __extends(TextMedia, _super);
    function TextMedia(el) {
        var _this = _super.call(this, el) || this;
        _this.controlPrev = null;
        _this.controlNext = null;
        _this.images = null;
        _this.currentIndex = 0;
        _this.currentIndexIndicator = null;
        _this.moduleName = null;
        _this.onSwipe = function (e) {
            if (e.detail.dir === 'left') {
                _this.onClickNext();
            }
            else if (e.detail.dir === 'right') {
                _this.onClickPrev();
            }
        };
        _this.onClickPrev = function (e) {
            var _a, _b, _c;
            e && e.preventDefault();
            if (!_this.images) {
                return;
            }
            if (_this.currentIndex > 0) {
                _this.currentIndex--;
                _this.images[_this.currentIndex + 1].classList.remove("".concat(_this.moduleName, "__figure--active"));
                _this.images[_this.currentIndex].classList.add("".concat(_this.moduleName, "__figure--active"));
                if (_this.currentIndexIndicator) {
                    _this.currentIndexIndicator.innerHTML = (_this.currentIndex + 1).toString();
                }
                if (_this.currentIndex === 0) {
                    (_a = _this.controlPrev) === null || _a === void 0 ? void 0 : _a.classList.add('swiper-button-disabled');
                }
                else {
                    (_b = _this.controlPrev) === null || _b === void 0 ? void 0 : _b.classList.remove('swiper-button-disabled');
                }
                if (_this.currentIndex < _this.images.length - 1) {
                    (_c = _this.controlNext) === null || _c === void 0 ? void 0 : _c.classList.remove('swiper-button-disabled');
                }
            }
        };
        _this.onClickNext = function (e) {
            var _a, _b, _c;
            e && e.preventDefault();
            if (!_this.images) {
                return;
            }
            if (_this.currentIndex < _this.images.length - 1) {
                _this.currentIndex++;
                _this.images[_this.currentIndex - 1].classList.remove("".concat(_this.moduleName, "__figure--active"));
                _this.images[_this.currentIndex].classList.add("".concat(_this.moduleName, "__figure--active"));
                if (_this.currentIndexIndicator) {
                    _this.currentIndexIndicator.innerHTML = (_this.currentIndex + 1).toString();
                }
                if (_this.currentIndex === _this.images.length - 1) {
                    (_a = _this.controlNext) === null || _a === void 0 ? void 0 : _a.classList.add('swiper-button-disabled');
                }
                else {
                    (_b = _this.controlNext) === null || _b === void 0 ? void 0 : _b.classList.remove('swiper-button-disabled');
                }
                if (_this.currentIndex > 0) {
                    (_c = _this.controlPrev) === null || _c === void 0 ? void 0 : _c.classList.remove('swiper-button-disabled');
                }
            }
        };
        _this.onMouseoverControlNext = function () {
            var _a;
            if ((_a = _this.controlNext) === null || _a === void 0 ? void 0 : _a.classList.contains('swiper-button-disabled')) {
                return;
            }
            _this.onMouseoverControl();
        };
        _this.onMouseoverControlPrev = function () {
            var _a;
            if ((_a = _this.controlPrev) === null || _a === void 0 ? void 0 : _a.classList.contains('swiper-button-disabled')) {
                return;
            }
            _this.onMouseoverControl();
        };
        _this.onMouseoverControl = function () {
            _this.el.classList.add('single-slider--mouseover');
        };
        _this.onMouseoutControl = function () {
            _this.el.classList.remove('single-slider--mouseover');
        };
        return _this;
    }
    TextMedia.prototype.init = function () {
        var _a, _b, _c, _d, _e, _f;
        this.controlPrev = this.el.querySelector('[data-slider-control-prev]');
        this.controlNext = this.el.querySelector('[data-slider-control-next]');
        this.images = this.el.querySelectorAll('figure');
        this.currentIndexIndicator = this.el.querySelector('[data-current-index]');
        this.moduleName = this.el.getAttribute('data-module-name');
        (_a = this.controlPrev) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.onClickPrev);
        (_b = this.controlNext) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this.onClickNext);
        (_c = this.controlNext) === null || _c === void 0 ? void 0 : _c.addEventListener('mouseover', this.onMouseoverControlNext);
        (_d = this.controlPrev) === null || _d === void 0 ? void 0 : _d.addEventListener('mouseover', this.onMouseoverControlPrev);
        (_e = this.controlNext) === null || _e === void 0 ? void 0 : _e.addEventListener('mouseout', this.onMouseoutControl);
        (_f = this.controlPrev) === null || _f === void 0 ? void 0 : _f.addEventListener('mouseout', this.onMouseoutControl);
        this.el.addEventListener('swiped', this.onSwipe);
    };
    TextMedia.prototype.destroy = function () {
        var _a, _b;
        (_a = this.controlPrev) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', this.onClickPrev);
        (_b = this.controlNext) === null || _b === void 0 ? void 0 : _b.removeEventListener('click', this.onClickNext);
    };
    return TextMedia;
}(component_1.Component));
exports.default = TextMedia;
