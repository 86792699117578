"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var Reload = /** @class */ (function (_super) {
    __extends(Reload, _super);
    function Reload(el) {
        var _this = _super.call(this, el) || this;
        _this.root = null;
        _this.computedStyle = null;
        _this.step = function () {
            var pos = _this.computedStyle.getPropertyValue('--pos');
            var newPos = parseFloat(pos) + .5;
            if (newPos > 100) {
                newPos = 0;
            }
            _this.root.style.setProperty('--pos', "".concat(newPos, "%"));
            window.requestAnimationFrame(_this.step);
        };
        return _this;
    }
    Reload.prototype.init = function () {
        if (typeof CSS.registerProperty === 'function') {
            CSS.registerProperty({
                name: '--pos',
                syntax: '<length-percentage>',
                initialValue: '0%',
                inherits: true,
            });
        }
        else {
            this.root = document.querySelector(':root');
            if (this.root) {
                this.computedStyle = getComputedStyle(this.root);
                this.step();
            }
        }
    };
    Reload.prototype.reload = function () {
        this.root.style.setProperty('--pos', '0%');
    };
    return Reload;
}(component_1.Component));
exports.default = Reload;
