"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(el) {
        var _this = _super.call(this, el) || this;
        _this.taxonomies = null;
        _this.tiles = null;
        _this.onClickTaxonomy = function (e) {
            if (!_this.tiles || !_this.taxonomies) {
                console.warn('Filter onClickTaxonomy(): tiles or taxonomies null.');
                return;
            }
            var taxonomy = e.target.getAttribute('data-taxonomy');
            _this.taxonomies.forEach(function (tabTitle) { return tabTitle.classList.remove('cta--tab--active'); });
            e.target.classList.add('cta--tab--active');
            _this.tiles.forEach(function (tile) {
                var taxonomies = tile.getAttribute('data-taxonomies').split(',');
                var tileColumn = tile.closest('.filter__column');
                if (taxonomies.includes(taxonomy)) {
                    tileColumn === null || tileColumn === void 0 ? void 0 : tileColumn.classList.remove('js-hidden--all');
                }
                else {
                    tileColumn === null || tileColumn === void 0 ? void 0 : tileColumn.classList.add('js-hidden--all');
                }
            });
        };
        return _this;
    }
    Filter.prototype.init = function () {
        var _this = this;
        this.taxonomies = this.el.querySelectorAll('.cta');
        this.tiles = this.el.querySelectorAll('.tile');
        this.taxonomies.forEach(function (tabTitle) {
            tabTitle.addEventListener('click', _this.onClickTaxonomy);
        });
    };
    Filter.prototype.destroy = function () {
        var _this = this;
        var _a;
        (_a = this.taxonomies) === null || _a === void 0 ? void 0 : _a.forEach(function (taxonomy) {
            taxonomy.removeEventListener('click', _this.onClickTaxonomy);
        });
    };
    return Filter;
}(component_1.Component));
exports.default = Filter;
