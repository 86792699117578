"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var TabModule = /** @class */ (function (_super) {
    __extends(TabModule, _super);
    function TabModule(el) {
        var _this = _super.call(this, el) || this;
        _this.tabTitles = null;
        _this.tabContents = null;
        _this.onClickTitle = function (e) {
            if (!_this.tabContents || !_this.tabTitles) {
                console.warn('TabModule onClickTitle(): tabContens or tabTitles null.');
                return;
            }
            var index = parseInt(e.target.getAttribute('data-tab-index'));
            _this.tabTitles.forEach(function (tabTitle) { return tabTitle.classList.remove('cta--tab--active'); });
            _this.tabContents.forEach(function (tabContent, i) {
                if (i < index) {
                    tabContent.classList.add('hidden--prev');
                }
                else if (i > index) {
                    tabContent.classList.add('hidden--next');
                }
                else {
                    tabContent.classList.remove('hidden--light', 'hidden--prev', 'hidden--next');
                }
            });
            _this.tabTitles[index].classList.add('cta--tab--active');
        };
        return _this;
    }
    TabModule.prototype.init = function () {
        var _this = this;
        this.tabTitles = this.el.querySelectorAll('.cta');
        this.tabContents = this.el.querySelectorAll('.tab-module__tab-content');
        this.tabTitles.forEach(function (tabTitle) {
            tabTitle.addEventListener('click', _this.onClickTitle);
        });
    };
    TabModule.prototype.destroy = function () {
        var _this = this;
        var _a;
        (_a = this.tabTitles) === null || _a === void 0 ? void 0 : _a.forEach(function (tabTitle) {
            tabTitle.removeEventListener('click', _this.onClickTitle);
        });
    };
    return TabModule;
}(component_1.Component));
exports.default = TabModule;
